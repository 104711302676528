<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Subastas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Comercialización</li>
                  <li class="breadcrumb-item active">Comercio</li>
                  <li class="breadcrumb-item active">Gestión Subastas</li>
                  <li class="breadcrumb-item active">Configuración</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div
                class="card-header pt-2 pb-2"
                v-if="$store.getters.can('com.subastas.create')"
              >
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th class="text-center">
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Fecha Inicio
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_ini"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Fecha Fin
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_fin"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">Fecha Max Oferta</th>
                      <th class="text-center">Tipo Contrato</th>
                      <th class="text-center">
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">
                        Estado Proceso
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado_proceso"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado_proceso in listasForms.estados_proceso"
                            :key="estado_proceso.numeracion"
                            :value="estado_proceso.numeracion"
                          >
                            {{ estado_proceso.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="col-1 text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in subastas.data" :key="item.id">
                      <td class="text-center">{{ item.id }}</td>
                      <td>{{ item.nombre }}</td>
                      <td class="text-center">{{ item.fecha_ini }}</td>
                      <td class="text-center">{{ item.fecha_fin }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            item.tipo_contrato == 1
                              ? ''
                              : 'alert-default-danger'
                          "
                        >
                          {{
                            item.tipo_contrato == 1
                              ? item.fecha_max_oferta
                              : "No Tiene"
                          }}
                        </span>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            item.tipo_contrato == 1
                              ? 'alert-default-primary'
                              : 'alert-default-success'
                          "
                        >
                          {{
                            item.tipo_contrato == 1
                              ? "Subasta"
                              : "Venta Directa"
                          }}
                        </span>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            item.estado == 1 ? 'badge-warning' : 'badge-success'
                          "
                        >
                          {{ item.nEstado }}
                        </span>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            item.estado_proceso == 1
                              ? 'badge-warning'
                              : item.estado_proceso == 2
                              ? 'badge-info'
                              : item.estado_proceso == 3
                              ? 'badge-primary'
                              : 'badge-danger'
                          "
                        >
                          {{ item.nEstadoProceso }}
                        </span>
                      </td>
                      <td>
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('com.subastas.edit')"
                            @click="edit(item)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              item.estado == 1 &&
                              $store.getters.can('com.subastas.delete')
                            "
                            @click="destroy(item.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-info"
                            v-if="
                              item.estado == 2 &&
                              item.estado_proceso == 1 &&
                              item.tipo_contrato == 1
                            "
                            data-toggle="modal"
                            data-target="#modal-form-mail"
                            @click="$refs.SubastaModalCorreo.getIndex(item.id)"
                          >
                            <i class="fas fa-envelope-square"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-secondary"
                            v-if="
                              $store.getters.can(
                                'com.subastas.asignarProponentesAdjudicados'
                              ) &&
                              (item.estado_proceso == 3 ||
                                item.estado_proceso == 2) &&
                              item.estado == 2 &&
                              (item.tipo_contrato == 1
                                ? item.fecha_max_oferta < ahora
                                : item.tipo_contrato == 2)
                            "
                            data-toggle="modal"
                            data-target="#modal-form-gan-oferta"
                            @click="
                              $refs.subastaModalGanOferta.getIndex(item.id)
                            "
                          >
                            <i class="fas fa-comment-dollar fa-md"></i>
                          </button>
                          <!-- <button
                            type="button"
                            class="btn btn-sm bg-lime"
                            v-if="
                              $store.getters.can(
                                'com.subastas.asignarProponentesAdjudicados'
                              ) && item.estado_proceso == 3
                            "
                            data-toggle="modal"
                            data-target="#modal-form-asignacion"
                            @click="$refs.subastaModalReAsig.getIndex(item.id)"
                          >
                            <i class="fas fa-file-signature"></i>
                          </button> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="subastas.total">
                  <p>
                    Mostrando del <b>{{ subastas.from }}</b> al
                    <b>{{ subastas.to }}</b> de un total:
                    <b>{{ subastas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="subastas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <SubastaModalCorreo ref="SubastaModalCorreo" />
    <SubastaModalGanOferta ref="subastaModalGanOferta" />
    <SubastaModalReAsig ref="subastaModalReAsig" />
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import SubastaModalCorreo from "./SubastaModalCorreo";
import SubastaModalGanOferta from "./SubastaModalGanOferta";
import SubastaModalReAsig from "./SubastaModalReAsig";
import moment from "moment";
import "moment/locale/es";

export default {
  name: "SubastaIndex",
  components: {
    pagination,
    Loading,
    SubastaModalCorreo,
    SubastaModalGanOferta,
    SubastaModalReAsig,
  },
  data() {
    return {
      ahora: moment().format("YYYY-MM-DD HH:mm:ss"),
      cargando: false,
      filtros: {},
      subastas: {},
      listasForms: {
        estados: [],
      },
    };
  },
  methods: {
    async getIndex(page = 1) {
      let me = this;
      me.cargando = true;
      await axios
        .get("/api/com/subastas?page=" + page, {
          params: me.filtros,
        })
        .then((response) => {
          me.subastas = response.data;
          me.cargando = false;
        })
        .catch(function (error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    fechaMaxOferta(fechaMaxOferta) {
      const fechaActual = new Date();
      const fechaMaxOfertaDate = new Date(fechaMaxOferta);
      return fechaActual > fechaMaxOfertaDate;
    },

    fechaMinOferta(fechaMaxOferta) {
      const fechaActual = new Date();
      const fechaMaxOfertaDate = new Date(fechaMaxOferta);
      return fechaActual < fechaMaxOfertaDate;
    },

    async getEstados() {
      await axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getEstadosProcesos() {
      await axios.get("/api/lista/186").then((response) => {
        this.listasForms.estados_proceso = response.data;
      });
    },

    create() {
      return this.$router.push({
        name: "/Com/SubastasForm",
        params: { id: null },
      });
    },

    edit(subasta) {
      return this.$router.push({
        name: "/Com/SubastasForm",
        params: { id: subasta.id },
      });
    },

    destroy(id) {
      this.$swal({
        title: "Está seguro de eliminar esta Subasta?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.cargando = true;
          let me = this;
          axios
            .delete("/api/com/subastas/" + id)
            .then(() => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se eliminó la Subasta exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            })
            .catch(function (error) {
              me.$swal({
                icon: "error",
                title: "Ocurrió un error:" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              me.cargando = false;
            });
        }
      });
    },
  },

  async mounted() {
    await this.getEstados();
    await this.getEstadosProcesos();
    await this.getIndex();
  },
};
</script>
